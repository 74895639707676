<template>
  <div id="container">
    <div
      :id="`editor-${id}`"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

let MyUploadAdapter = null

export default {
  name: 'BaseEditor2',
  props: {
    value: {
      type: String,
      default: '',
    },
    imageProviderUrl: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      default: 'default',
    },
  },
  emits: ['input'],
  data() {
    return {
      editorData: null,
      timeout: null,
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
    }),
  },
  mounted() {
    if (document.getElementById(`d-ckeditor-${this.id}`)) {
      window?.CKEDITOR?.ClassicEditor.create(document.getElementById(`editor-${this.id}`), {
        // https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
        toolbar: {
          viewportTopOffset: 84,
          items: [
            // 'exportPDF', 'exportWord', '|',
            'undo', 'redo', '|',
            'heading', '|',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|', // 'highlight',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', 'removeFormat', '|', // 'code',
            'link', 'uploadImage', 'blockQuote', 'insertTable', 'mediaEmbed', '|', // 'codeBlock', 'htmlEmbed',
            'alignment', '|',
            'bulletedList', 'numberedList', '|', // 'todoList',
            'outdent', 'indent', '|',
            // 'findAndReplace', 'selectAll', '|',
            // '-',
            // 'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            // 'textPartLanguage', '|',
            // 'sourceEditing',
          ],
          shouldNotGroupWhenFull: true,
        },
        // Changing the language of the interface requires loading the language file using the <script> tag.
        // language: 'es',
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        extraPlugins: [this.uploader],
        // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            // { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            // { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            // { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            // { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          ],
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
        placeholder: 'please type here...',
        // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-family-feature
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
          supportAllValues: true,
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22, 24, 26, 28],
          supportAllValues: true,
        },
        // Be careful with the setting below. It instructs CKEditor to accept ALL HTML markup.
        // https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html#enabling-all-html-features
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        // Be careful with enabling previews
        // https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
        htmlEmbed: {
          showPreviews: true,
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file',
              },
            },
          },
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                '@sugar', '@sweet', '@topping', '@wafer',
              ],
              minimumCharacters: 1,
            },
          ],
        },
        // The "super-build" contains more premium features that require additional configuration, disable them below.
        // Do not turn them on unless you read the documentation and know how to configure them and setup the editor.
        removePlugins: [
          // These two are commercial, but you can try them out without registering to a trial.
          'ExportPdf',
          'ExportWord',
          'AIAssistant',
          'CKBox',
          'CKFinder',
          'EasyImage',
          // This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
          // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
          // Storing images as Base64 is usually a very bad idea.
          // Replace it on production website with other solutions:
          // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
          'Base64UploadAdapter',
          'RealTimeCollaborativeComments',
          'RealTimeCollaborativeTrackChanges',
          'RealTimeCollaborativeRevisionHistory',
          'PresenceList',
          'Comments',
          'TrackChanges',
          'TrackChangesData',
          'RevisionHistory',
          'Pagination',
          'WProofreader',
          // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
          // from a local file system (file://) - load this site via HTTP server if you enable MathType.
          'MathType',
          // The following features are part of the Productivity Pack and require additional license.
          'SlashCommand',
          'Template',
          'DocumentOutline',
          'FormatPainter',
          'TableOfContents',
          'PasteFromOfficeEnhanced',
        ],
      }).then((editor) => {
        editor.setData(this.value || '')
        this.editorData = editor

        editor.model.document.on('change:data', () => {
          this.timeout && clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.$emit('input', editor.getData())
          }, 500)
        })

      }).catch((error) => {
        console.error(error)
      })
      return // was already loaded
    }
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://cdn.ckeditor.com/ckeditor5/40.2.0/super-build/ckeditor.js'
    scriptTag.id = `d-ckeditor-${this.id}`
    document.getElementsByTagName('head')[0].appendChild(scriptTag)
    scriptTag.onload = () => {
      window?.CKEDITOR?.ClassicEditor.create(document.getElementById(`editor-${this.id}`), {
        // https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
        toolbar: {
          viewportTopOffset: 84,
          items: [
            // 'exportPDF', 'exportWord', '|',
            'undo', 'redo', '|',
            'heading', '|',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|', // 'highlight',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', 'removeFormat', '|', // 'code',
            'link', 'uploadImage', 'blockQuote', 'insertTable', 'mediaEmbed', '|', // 'codeBlock', 'htmlEmbed',
            'alignment', '|',
            'bulletedList', 'numberedList', '|', // 'todoList',
            'outdent', 'indent', '|',
            // 'findAndReplace', 'selectAll', '|',
            // '-',
            // 'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            // 'textPartLanguage', '|',
            // 'sourceEditing',
          ],
          shouldNotGroupWhenFull: true,
        },
        // Changing the language of the interface requires loading the language file using the <script> tag.
        // language: 'es',
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        extraPlugins: [this.uploader],
        // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            // { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            // { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            // { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            // { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          ],
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
        placeholder: 'please type here...',
        // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-family-feature
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
          supportAllValues: true,
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22, 24, 26, 28],
          supportAllValues: true,
        },
        // Be careful with the setting below. It instructs CKEditor to accept ALL HTML markup.
        // https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html#enabling-all-html-features
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        // Be careful with enabling previews
        // https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
        htmlEmbed: {
          showPreviews: true,
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file',
              },
            },
          },
        },
        // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                '@sugar', '@sweet', '@topping', '@wafer',
              ],
              minimumCharacters: 1,
            },
          ],
        },
        // The "super-build" contains more premium features that require additional configuration, disable them below.
        // Do not turn them on unless you read the documentation and know how to configure them and setup the editor.
        removePlugins: [
          // These two are commercial, but you can try them out without registering to a trial.
          'ExportPdf',
          'ExportWord',
          'AIAssistant',
          'CKBox',
          'CKFinder',
          'EasyImage',
          // This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
          // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
          // Storing images as Base64 is usually a very bad idea.
          // Replace it on production website with other solutions:
          // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
          'Base64UploadAdapter',
          'RealTimeCollaborativeComments',
          'RealTimeCollaborativeTrackChanges',
          'RealTimeCollaborativeRevisionHistory',
          'PresenceList',
          'Comments',
          'TrackChanges',
          'TrackChangesData',
          'RevisionHistory',
          'Pagination',
          'WProofreader',
          // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
          // from a local file system (file://) - load this site via HTTP server if you enable MathType.
          'MathType',
          // The following features are part of the Productivity Pack and require additional license.
          'SlashCommand',
          'Template',
          'DocumentOutline',
          'FormatPainter',
          'TableOfContents',
          'PasteFromOfficeEnhanced',
        ],
      }).then((editor) => {
        editor.setData(this.value || '')
        this.editorData = editor

        editor.model.document.on('change:data', () => {
          this.timeout && clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.$emit('input', editor.getData())
          }, 500)
        })

      }).catch((error) => {
        console.error(error)
      })
    }

  },
  created() {
    const that = this
    class MyUploadAdapterInit {
      constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader
        this.controller = new AbortController()
      }

      // Starts the upload process.
      upload() {
        return this.loader.file
          .then(file => new Promise((resolve, reject) => {
            const data = new FormData()
            data.append('file', file)
            that.axios.post(that.imageProviderUrl, data, {
              signal: this.controller.signal,
              onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                  this.loader.uploadTotal = progressEvent.total
                  this.loader.uploaded = progressEvent.loaded
                }
              },
            })
              .then((res) => {
                resolve({
                  default: res.data?.data?.url,
                })
              }).catch(() => {
                reject('Upload image failed!')
              })
          }))
      }

      // Aborts the upload process.
      abort() {
        this.controller.abort()
      }
    }
    MyUploadAdapter = MyUploadAdapterInit
  },
  methods: {
    uploader(editor) {
      if (!editor) return
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader)
      }
    },
  },
}
</script>

<style>
#container {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
}
.ck-editor__editable[role="textbox"] {
  /* editing area */
  min-height: 200px;
}
.ck-content .image {
  /* block images */
  max-width: 80%;
  margin: 20px auto;
}
</style>

<!-- NOTE: Please Override CKEditor Styling on global css-->